<template>
  <div class="flex gap-2 h-full min-h-0 border-l border-gray-200">
    <div class="bg-gray-50 w-full flex-1 h-full min-h-0 flex flex-col">
      <div class="flex ring-1 ring-gray-200">
        <button
          v-if="allowedTabs.includes('editor')"
          class="sidebar-button"
          :class="{
            active: sidebar == 'editor'
          }"
          @click="changeTab('editor')"
        >
          <fw-icon-text-box class="h-5 w-5"></fw-icon-text-box>
        </button>
        <button
          v-if="allowedTabs.includes('versions')"
          class="sidebar-button"
          :class="{ active: sidebar == 'versions' }"
          @click="changeTab('versions')"
        >
          <fw-icon-git-branch-line class="h-5 w-5" />
        </button>
        <button
          v-if="allowedTabs.includes('comments')"
          class="sidebar-button"
          :class="{ active: sidebar == 'comments' }"
          @click="changeTab('comments')"
        >
          <fw-icon-discuss class="h-5 w-5"></fw-icon-discuss>
        </button>
        <button
          v-if="allowedTabs.includes('translations')"
          class="sidebar-button"
          :class="{ active: sidebar == 'translations' }"
          @click="changeTab('translations')"
        >
          <fw-icon-translate class="h-5 w-5"></fw-icon-translate>
        </button>
        <button
          v-if="canOpenSettings"
          class="sidebar-button"
          :class="{ active: sidebar == 'settings' }"
          @click="changeTab('settings')"
        >
          <fw-icon-settings class="h-5 w-5"></fw-icon-settings>
        </button>
      </div>
      <sidebar-block-detail
        v-if="sidebar == 'editor' && allowedTabs.includes('editor') && currentVersion"
        :key="
          'sidebar-block-detail-' +
            currentVersion.key +
            (activeBlock ? activeBlock.segmentId : '') +
            (activeBlock && comments[activeBlock.segmentId] ? comments[activeBlock.segmentId].length : 0) +
            sidebarKey
        "
        :block="activeBlock"
        :comments="activeBlock && activeBlock.segmentId ? comments[activeBlock.segmentId] : comments['document']"
        :users="users"
        :version="currentVersion"
        :myrole="myrole"
        :editor="editor"
        :editable="editable"
        :can-comment="permissions.comment"
        @open-comment="$emit('open-comment', $event)"
        @new-comment="$emit('new-comment', $event)"
        @edit-comment="$emit('edit-comment', $event)"
        @delete-comment="$emit('delete-comment', $event)"
        @close="$emit('set-active-block', null)"
      />
      <PanelSideVersions
        v-else-if="sidebar == 'versions' && allowedTabs.includes('versions')"
        :myrole="myrole"
        :versions="versions"
        :current-version="currentVersion"
        :page="page"
        :permissions="permissions"
        @generate-pdf="$emit('generate-pdf', $event)"
        @change-version="$emit('change-version', $event)"
        @snapshot="$emit('snapshot', $event)"
        @publish="$emit('publish', $event)"
      ></PanelSideVersions>
      <PanelSideTranslations
        v-else-if="sidebar == 'translations' && allowedTabs.includes('translations')"
        :myrole="myrole"
        :page="page"
        :versions="versions"
        :current-version="currentVersion"
        @change-version="$emit('change-version', $event)"
        @snapshot="$emit('snapshot')"
        @new-language="$emit('new-language')"
      ></PanelSideTranslations>
      <PanelSideComments
        v-else-if="sidebar == 'comments' && allowedTabs.includes('comments')"
        :myrole="myrole"
        :page="page"
        :version="currentVersion"
        :comments="commentsList"
        :users="users"
        :can-comment="permissions.comment"
        @open-comment="$emit('open-comment', $event)"
        @new-comment="$emit('new-comment', $event)"
        @edit-comment="$emit('edit-comment', $event)"
        @delete-comment="$emit('delete-comment', $event)"
      />
      <PanelSideSettings
        v-else-if="sidebar == 'settings' && canOpenSettings"
        :page="page"
        :myrole="myrole"
        :translator="translator"
        :version="currentVersion"
        :permissions="permissions"
        :flow-users="flowUsers"
        @import-file="$emit('import-file', $event)"
        @export-file="$emit('export-file', $event)"
        @share-document="$emit('share-document', $event)"
        @change-state="$emit('change-state', $event)"
        @delete-document="$emit('delete-document', $event)"
        @delete-version="$emit('delete-version', $event)"
        @flow-update="$emit('flow-update', $event)"
      />
    </div>
  </div>
</template>
<script>
export default {
  components: {
    //PanelSideEditor: () => import('@/fw-modules/fw-core-vue/pages/components/panels/PanelSideEditor.vue'),
    PanelSideVersions: () => import('@/fw-modules/fw-core-vue/pages/components/panels/PanelSideVersions.vue'),
    PanelSideComments: () => import('@/fw-modules/fw-core-vue/pages/components/panels/PanelSideComments.vue'),
    PanelSideSettings: () => import('@/fw-modules/fw-core-vue/pages/components/panels/PanelSideSettings.vue'),
    PanelSideTranslations: () => import('@/fw-modules/fw-core-vue/pages/components/panels/PanelSideTranslations.vue'),
    SidebarBlockDetail: () => import('@/fw-modules/fw-core-vue/pages/components/sidebars/SidebarBlockDetail.vue')
  },
  props: {
    myrole: {
      type: String,
      required: true
    },
    editable: {
      type: Boolean,
      default: true
    },
    activeCommands: {
      type: Object,
      default: () => ({})
    },
    sidebarKey: {
      type: Number,
      default: 0
    },
    selectedCommand: {
      type: String,
      default: ''
    },
    activeBlock: {
      type: Object,
      default: null
    },
    activeBlockType: {
      type: String,
      default: null
    },
    comments: {
      type: Object,
      default: () => ({})
    },
    editor: {
      type: Object,
      default: () => null
    },
    sidebar: {
      type: String,
      default: 'comments'
    },
    currentStyle: {
      type: String,
      default: 'paragraph'
    },
    versions: {
      type: Array,
      default: () => []
    },
    currentVersion: {
      type: Object,
      default: () => ({})
    },
    translator: {
      type: Boolean,
      default: false
    },
    users: {
      type: Object,
      default: () => ({})
    },
    commentsList: {
      type: Array,
      default: () => []
    },
    page: {
      type: Object,
      required: true
    },
    allowedTabs: {
      type: Array,
      default: () => ['versions', 'comments', 'settings'] // , 'translations'
    },
    permissions: {
      type: Object,
      default: () => ({})
    },
    flowUsers: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    debugMode() {
      return Boolean(localStorage.getItem('fw-debug'))
    },
    canOpenSettings() {
      return (
        this.allowedTabs.includes('settings') &&
        (this.permissions.update_status ||
          this.permissions.share ||
          this.permissions.import_file ||
          this.permissions.export_file ||
          this.permissions.delete)
      )
    }
  },
  mounted() {
    if (!this.allowedTabs.includes(this.sidebar)) {
      this.changeTab(this.allowedTabs[0])
    }
  },
  watch: {
    activeBlock(newBlock, oldBlock) {
      if (
        newBlock != null &&
        (oldBlock == null || newBlock.segmentId != oldBlock.segmentId) &&
        this.allowedTabs.includes('editor')
      ) {
        this.$emit('tab', 'editor')
      }
    }
  },
  methods: {
    changeTab(tab) {
      //this.$emit('set-active-block', null)
      this.$emit('tab', tab)
    }
  }
}
</script>
<style scoped>
.sidebar-button {
  transition: all 0.2s ease-in-out;
  @apply flex-1 border-b-2 px-2 pb-3 pt-4 flex items-center justify-center border-transparent text-gray-400 hover:text-gray-600 hover:bg-gray-100;
}
.sidebar-button.active {
  @apply border-primary text-primary bg-primary bg-opacity-5;
}
</style>
